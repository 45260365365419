<template>
    <nav class="header_nav_container">
        <div class="header_center">
            <div v-click-outside="handleOutsideClick" class="list-unstyled list-inline nav_list">
                <div class="header_sign_in">
                    <div v-if="!appState.loggedIn" class="dropdown">
                        <div>
                            <router-link :to="'/my'" class="btn header_create_quote" tabindex="4">
                                        <span>
                                            SIGN IN
                                        </span>
                            </router-link>

                        <div class="btn header_create_quote">
                            <router-link v-show="appState.user.userTypeID != 5" class="btn_user" :to="`/quote/new`">
                                CREATE A QUOTE</router-link>
                        </div>
                        </div>
                    </div>
                    <div v-else-if="!appState.loggedIn" class="dropdown" >
                        <div>
                            <button class="btn nav_right_btn" @click="toggleLogIn">SIGN IN</button>
                            <div class="dropdown-menu dropdown-menu-right login_panel" :class="[showLogIn ? 'cdi_show' : 'cdi_hide']">
                                <form action="/my/signin" method="post" @submit.prevent="signIn">
                                    <div class="form-group">
                                        <label>Email</label><input type="text" class="form-control" tabindex="1" v-model="formFields.email.val">
                                    </div>
                                    <div class="form-group">
                                        <label>Password</label><a href="/pass_reset" class="pull-right small forgot-pass" tabindex="5">Forgot password?</a><input type="password" class="form-control" tabindex="2" v-model="formFields.password.val">
                                    </div>
                                    <input type="submit" value="SIGN IN" class="btn header_create_quote" tabindex="3">
                                    <router-link :to="'/my/register'" class="btn btn-default create-account" tabindex="4">
                                        <span @click="handleOutsideClick">
                                            Create Your Account
                                        </span>
                                    </router-link>
                                </form>
                            </div>

                        </div>
                    </div>
                    <HeaderMyCDI v-else />
                </div>
                <div>
                <div class="tiny_logo_name">
                    <a id="home-icon" href="/">
                        <img src="../../assets/cdi-logo-wheel-color-mobile.png" class="name" id="mobile-wheel">
                        <span class="name" id="name"> <b> CDI curbs</b></span>
                    </a>
                </div>
                </div>
                <div><NavLinks class="nav_links" /></div>
            </div>
        </div>
    </nav>
</template>

<script>
    import NavLinks from './NavLinks';
    import HeaderMyCDI from "@/components/layout/HeaderMyCDI.vue";
    import clickOutside from "@/directives/clickOutside";
    export default {
        name: "Nav",
        components: {
            HeaderMyCDI,
          NavLinks,
        },
        data() {
            return {
                appState: this.$appStore.state,
            }
        },
        methods:{
            toggleLogIn: function() {
                this.showLogIn = !this.showLogIn;
            },
            signIn: function() {
                this.$appStore.signIn(this.formFields);
                this.showLogIn = false;
                this.formFields.email.val = '';
                this.formFields.password.val = '';
            },
            handleOutsideClick: function () {
                this.showLogIn = false;
                this.showMyCDI = false;
            },
        },
        directives: {
            clickOutside: clickOutside
        }
    }
</script>

<style scoped>
.header_nav_container{
    background-color: #343538;
}
.header_create_quote {
    color: #fff !important;
}
</style>
