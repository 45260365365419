import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import { cdiVars } from './cdiVars';
import appStore from './store/App.store'
import './registerServiceWorker'

appStore.loadToken();

Vue.config.productionTip = false
Vue.prototype.$http = axios;
Vue.prototype.$cdiVars = cdiVars;
Vue.prototype.$appStore = appStore;
Vue.prototype.$isEmpty = function (value) {
  return (
      value === undefined ||
      value === null ||
      (typeof value === 'object' && Object.keys(value).length === 0) ||
      (typeof value === 'string' && value.trim().length === 0) ||
      parseInt(value) === 0
  );
};

Vue.filter('currency', function (value, currency = 'USD', decimalPlaces = 2, round = 'round') {
  value = parseFloat(value).toFixed(decimalPlaces);
  if (round === 'round') {
    value = Math.round(value);
  } else if (round === 'ceil') {
    value = Math.ceil(value);
  } else if (round === 'floor') {
    value = Math.floor(value);
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency
  }).format(value);
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');