<template>
    <transition name="modal_fade" >
        <div  @keydown.esc="$emit('close')" >
            <div :class="classes" class="modal" role="dialog"  v-draggable @dragstart="setModal">
                <div class="modal_header">
                    {{heading}}
                    <button type="button" class="modal_btn_close" @click="$emit('close')" aria-label="Close modal"><span class="glyphicon glyphicon-remove"></span></button>
                </div>
                <div class="modal_body">
                    <slot name="body"></slot>
                </div>
                <div class="modal_footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import draggable from '@/directives/draggable';
    import {fileUploadStore} from '@/store/FileUpload.store';

    export default {
        name: "Modal",
        props: ['heading', 'classes'],
        directives: {
            draggable
        },
        methods: {
          setModal: function () {
             fileUploadStore.state.isModal = true;
          }
        }
    }
</script>

<style scoped>
.photoComponent{
    max-width: 200%!important;
    max-height: 200%!important;
    position: fixed;
    width: 100vw!important;
    height: 200vh!important;
    background-color: black;
    z-index: 20000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    padding-bottom: 25px;
    margin-top: 10%;
}
@media (max-width: 768px) {
  .modal{
    position: fixed;
    margin-top: 20%;
    max-height: 700px;
    overflow-y: scroll;
  }
    .taskDialog{
        max-width: 200%!important;
        max-height: 150%!important;
        min-width: 100%;
        z-index: 10000!important;
        overflow-y: scroll;
        height: 100%;
        margin-top: 0;
        left: 50%;
        top: 50%;
    }
}

@media (max-height: 450px) {
    .taskDialog{
        max-width: 200%!important;
        max-height: 150%!important;
        min-width: 100%;
        z-index: 10000!important;
        overflow-y: scroll;
        height: 100%;
        left: 50%;
        top: 50%;
        margin-top: 0!important;
    }
}

@media (min-width: 769px) {
  .modal{
    position: fixed;
    margin-top: 15%;
    max-height: 700px;
    overflow-y: auto;
  }
}

</style>