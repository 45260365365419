<template>
    <transition name="modal_fade">
        <div class="modal_backdrop">
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">

                <div class="modal_header" id="modalTitle">
                    CDI Error
                    <button type="button" class="modal_btn_close" @click="close" aria-label="Close modal"><span class="glyphicon glyphicon-remove"></span></button>
                </div>

                <div class="modal_body" id="modalDescription">
                    {{state.errorText}}
                </div>

            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "ErrorBox",
        data() {
            return {
                state: this.$appStore.state,
            }
        },
        methods: {
            close: function () {
                this.$appStore.errorBox('');
            }
        }
    }
</script>

<style scoped>
    .modal_header {
        color: #a94442;
        font-weight: bold;
        background-color: #f2dede;
    }
</style>