export const cdiVars = {
    
    BASE_URL: process.env.VUE_APP_URL,
    
    // end points
    API_URL: `${process.env.VUE_APP_URL}/api`,
    API_REPORT_URL: `${process.env.VUE_APP_URL}/rapi`,
    DOWNLOAD_URL: `${process.env.VUE_APP_URL}/api/download`,
    PROTECTED_DOWNLOAD_URL: `${process.env.VUE_APP_URL}/download`,
    
    // cdi roles
    CDI_ROLE_SUPER: 1,
    CDI_ROLE_HR: 2,
    CDI_ROLE_SALES: 3,
    CDI_ROLE_ACCOUNTING: 4,
    CDI_ROLE_ENG: 5,
    CDI_ROLE_QC: 6,
    CDI_ROLE_SHOP: 7,
    CDI_ROLE_SHOP_SUP: 8,
    CDI_ROLE_LOGISTICS: 9,
    CDI_ROLE_MANAGER: 10,
    CDI_ROLE_MATERIAL: 11,
    CDI_ROLE_LEADERSHIP: 12,
    CDI_ROLE_INSIDE_SALES: 13,
    CDI_ROLE_BATCHING: 14,
    
    // user types
    USER_TYPE_DISTRIBUTOR: 1,
    USER_TYPE_VENDOR: 3,
    USER_TYPE_EMPLOYEE: 4,
    USER_TYPE_NEW_USER: 5,
    USER_TYPE_WAREHOUSE: 6,
    USER_TYPE_SHIPPER: 7,
    USER_TYPE_API: 8,
    USER_TYPE_CONTACT: 9,
    USER_TYPE_CONTRACTOR: 10,

    // Special Users
    MANAGER_ACCOUNTING: 25277,
    
    // file types
    FILE_TYPE_TWOD_DRWNG: 1,
    FILE_TYPE_THREED_DRWNG: 2,
    FILE_TYPE_THREED_CONFIG_DRWNG: 3,
    FILE_TYPE_THREED_PART_CONFIG_DRWNG: 4,
    FILE_TYPE_QUOTE_CUSTOM_DRWNG: 5,
    FILE_TYPE_QUOTE_INTERNAL_FL: 6,
    FILE_TYPE_TRACKER_FL: 7,
    FILE_TYPE_STATIC_FL: 8,
    FILE_TYPE_TRACKER_PHT: 9,
    FILE_TYPE_ENGINEERING_FL: 10,
    FILE_TYPE_ENGINEERING_CUSTOM_FL: 11,
    FILE_TYPE_TRACKER_CUSTOM_DRWNG: 12,
    FILE_TYPE_TWELVEFIFTY_ENGINEERING_FL: 13,
    FILE_TYPE_HTHIRTY_ENGINEERING_FL: 14,
    FILE_TYPE_OMEGA_ENGINEERING_FL: 15,
    FILE_TYPE_GNSIX_ENGINEERING_FL: 16,
    FILE_TYPE_COMPANY_FL: 17,
    FILE_TYPE_UNASSIGNED_FL: 18,
    FILE_TYPE_WELDING_FL: 19,
    FILE_TYPE_KIT_CURB_BRAKE_FL: 20,
    FILE_TYPE_VENDOR_PO_FL: 21,
    FILE_TYPE_USER_PHT: 22,
    FILE_TYPE_ECO_FL: 23,
    FILE_TYPE_ASSET_PHT: 24,
    FILE_TYPE_ASSET_FL: 25,
    FILE_TYPE_HOLIDAY_FL: 26,
    FILE_TYPE_USER_LIBRARY_PHT: 27,
    FILE_TYPE_TWELVEFIFTYS_ENGINEERING_FL: 28,
    FILE_TYPE_ITEM_BATCH_FL: 29,
    FILE_TYPE_MATERIAL_FL: 30,
    FILE_TYPE_GLOBAL30_1225_FL: 31,
    FILE_TYPE_GLOBAL30_1525_FL: 32,
    FILE_TYPE_ASSEM_PARTLABEL_FL: 33,
    FILE_TYPE_STRIPPIT_1525M_FL: 34,
    FILE_TYPE_FIELD_ASSEM_DRWNG: 35,
    FILE_TYPE_PART_ENGINEERING_DETAIL: 36,
    FILE_TYPE_PRODUCTION_NOTES: 37,
    FILE_TYPE_SHIPPING_DATA: 38,
    FILE_TYPE_E_DRAWING: 39,
    FILE_TYPE_XML_TO_ORDER: 40,
    FILE_TYPE_CURB_TRAINING_DOCUMENT: 41,
    FILE_TYPE_ASSET_MAINTENANCE_DOCUMENT: 42,
    FILE_TYPE_TRACKER_MER_DOCUMENT: 43,
    FILE_TYPE_CONFORMING_STAMP: 44,
    FILE_TYPE_SORTING_STICKERS: 45,
    FILE_TYPE_MAINTENANCE_PHOTO: 46,
    FILE_TYPE_MAINTENANCE_SERVICE_RECORD: 47,

    INVENTORY_TYPE_STEEL: 4
}
