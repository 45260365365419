<template>
    <div id="alert_box" class="alert alert-warning" v-html="state.appMessage"></div>
</template>

<script>
    export default {
        name: "MessageBox",
        data() {
            return {
                state: this.$appStore.state,
            }
        }
    }
</script>

<style scoped>

</style>