import helpers from "@/store/helpers";
import { cdiVars } from "@/cdiVars";
import http from "axios";
import appStore from "@/store/App.store";

export const fileUploadStore = {
	baseUrl: process.env.VUE_APP_URL,
	state: {
		file: null,
		progress: 0,
		fileID: null,
		fileTypeID: cdiVars.FILE_TYPE_UNASSIGNED_FL,
		referenceID: null,
		reference2ID: null,
		conformingTypeID: null,
		fileTitle: null,
		fileName: null,
		allowAttach: true,
		errors: [],
		warnings: [],
		isModal: false
	},
	uploadFile: function () {
		let formData = new FormData();
		formData.append('files[]', this.state.file);

		http.post(`${ this.baseUrl }/api/cdi_file/upload`, formData,
			{
				headers: helpers.authHeader(),
				onUploadProgress: function (progressEvent) {
					this.state.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				}.bind(this)
			}
		).then(response => {
			if (response.data.opt === 'error') {
				this.$appStore.errorBox(response.data.msg);
				
				return;
			}
			
			this.state.fileName = response.data.file_name;
			this.state.fileID = response.data.cdi_file_id;
		}).catch();
	},
	attachFile: function () {
		let fn = appStore.state.fileUploadCallback;
		
		let queryString = helpers.getQSString(this.options());
		http.post(`${ this.baseUrl }/api/cdi_file/attach_file`, queryString, {headers: helpers.authHeader()}).then(result => {
			switch (result.data.opt) {
				case 'error':
					appStore.errorBox(result.data.msg);
					break;
				case 'warning':
					appStore.warningBox(result.data.msg);
					break;
				default:
					appStore.successBox('File Attached');
					break;
			}

			if (typeof fn === 'function') {
				fn();

			}
			appStore.state.synchronizeFileCallbackView = true;

			this.resetData();
		});
	},
	setFile: function (file) {
		this.state.file = file;
	},
	setTitle: function (title) {
		this.state.fileTitle = title;
	},
	initialize: function (fileTypeID = cdiVars.FILE_TYPE_UNASSIGNED_FL) {
		this.state.fileTypeID = fileTypeID;
		this.state.referenceID = appStore.state.fileUploadContextData.referenceID;
		this.state.reference2ID = appStore.state.fileUploadContextData.reference2ID;
		this.state.conformingTypeID = appStore.state.fileUploadContextData.conformingTypeID;
	},
	blankReferenceID: function () {
		this.state.referenceID = '';
	},
	resetData: function (keepFileData = false) {
		if (!keepFileData) {
			this.state.file = null;
			this.state.progress = 0;
			this.state.fileID = null;
			this.state.fileTitle = null;
			this.state.fileName = null;
		}
		
		this.state.allowAttach = true;
		
		this.state.fileTypeID = cdiVars.FILE_TYPE_UNASSIGNED_FL;
		this.state.referenceID = 0;
		this.state.reference2ID = 0;
		this.state.conformingTypeID = 0;
		
		appStore.setFileUploadContext(null);
		appStore.setFileUploadContextData({
			referenceID: 0,
			reference2ID: 0,
			data: {},
			metadata: {},
			conformingTypeID: 0,
		});
		appStore.setFileUploadCallback(function () {});
	},
	options: function () {
		let options = {
			'params[handler]': appStore.state.fileUploadContext,
			'params[cdi_reference_id]': this.state.referenceID,
			'params[cdi_reference2_id]': this.state.reference2ID,
			'params[cdi_file_type_id]': this.state.fileTypeID,
			'params[cdi_file_title]': this.state.fileName,
			'params[cdi_file_id]': this.state.fileID,
			'params[conforming_type_id]': this.state.conformingTypeID,
		};
		
		if (this.state.trackerID) {
			options['params[tracker_id]'] = this.state.trackerID;
		}
		
		if (this.state.quoteID) {
			options['params[quote_id]'] = this.state.quoteID;
		}
		
		return options;
	},
	loadMetadata: function () {
		let queryString = helpers.getQSString(this.options());
		http.post(`${ this.baseUrl }/api/cdi_file/get_metadata`, queryString, {headers: helpers.authHeader()}).then(result => {
			appStore.setFileUploadContextData({
				referenceID: this.state.referenceID,
				reference2ID: this.state.reference2ID,
				metadata: result.data
			});
		});
	}
}