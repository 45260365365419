<template>
  <Modal id="upload" heading="Mass File Upload" @close="close">
    <template v-slot:body>
      <div v-if="state.file !== null">
        <keep-alive>
          <component v-bind:is="currentComponent" style="overflow-y: scroll!important; max-height: 425px; margin-bottom: 5px"></component>
        </keep-alive>
      </div>

      <!-- TODO maybe a watch method or something to auto-trigger the upload instead of showing this to the user -->
      <div class="form-group" v-show="!state.file && state.progress === 0">
        <label class="btn btn-default">
          Choose file
          <input type="file" ref="file" name="files[]" @change="uploadFile()" />
        </label>
      </div>
      <div v-show="state.file && appState.fileUploadContext !== 'cdi_file'">
                <span v-show="state.allowAttach">
                    <button class="btn btn-primary btn-block" type="button" @click='holdFile()' >
                        Upload file
                    </button>
                </span>
        <button class="btn btn-default btn-block" type="button" @click='startOver()'>Start over</button>
      </div>
      <br>
      <small>All file uploads are subject to our <a href="/privacy_policy" target="_blank">Privacy Policy</a></small>
    </template>
  </Modal>
</template>

<script>
import { massFileUploadStore } from "@/store/MassFileUpload.store";
import Modal from "@/components/utils/Modal";
import {store} from "@/store/BusinessObject.store";
export default {
  name: "MassFileUpload",
  components: {
    Modal,
    conforming_mass_upload: () => import('@/components/utils/fileHandlers/ConformingMassUpload'),
  },
  created() {
    this.$appStore.setFileMassUploadCallback(function () {
      store.load('', 'mass_upload');
    });
  },
  data() {
    return {
      state: massFileUploadStore.state,
      appState: this.$appStore.state,
    }
  },
  methods: {
    uploadFile: function () {
      massFileUploadStore.setFile(this.$refs.file.files[0]);
      massFileUploadStore.setTitle(this.$refs.file.files[0].name);
    },
    holdFile: function() {
      massFileUploadStore.state.massUploadFilePresent = true;
      this.close();
    },
    startOver: function () {
      this.$refs.file.value = [];
      massFileUploadStore.resetData(false);
      this.$appStore.setFileMassUploadContext('conforming_mass_upload');
    },
    close: function () {
      this.$appStore.state.fileUploadModalOpen = false;
      this.$refs.file.value = [];
    }
  },
  computed: {
    currentComponent: function() {
      if (!this.appState.massFileUploadContext) {
        return null;
      }
      let current = `${this.appState.massFileUploadContext}`;
      this.$appStore.setFileMassUploadContext(current);
      return current;
    },
    conformingTypeID: function() {
      return this.state.conformingTypeID;
    },
    fileUploadContext: function() {
      return this.appState.massFileUploadContext;
    },
    fileUploadModalOpen: function () {
      return this.appState.fileUploadModalOpen;
    }
  },
  watch: {}
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}
</style>