export default {
    getQSPairs: function(data, paramsPrepend = 'params') {
        let qsPairs = {};
        
        for (const field in data) {
            let value = data[field]['val'];

            if (qsPairs[`${paramsPrepend}${data[field]['qsField']}`]) {
                if (qsPairs[`${paramsPrepend}${data[field]['qsField']}`].length) {
                    if (data[field]['qsField']){
                        qsPairs[`${paramsPrepend}${ data[field]['qsField'] }`].push(value);
                    }
                } else {
                    qsPairs[`${paramsPrepend}${data[field]['qsField']}`] = [qsPairs[`params${data[field]['qsField']}`], value];
                }
            } else {
                qsPairs[`${paramsPrepend}${data[field]['qsField']}`] = value;
            }
        }
        
        return qsPairs;
    },
    getQSString: function(qsPairs) {
       const qs = require('qs');
       return qs.stringify(qsPairs);
    },

    authHeader: function() {
        let token = localStorage.getItem('token');
        if (token) {
            return { Authorization: `Bearer ${token}` }
        }else{
            return {};
        }
    },
    
    canAccess: function(accessRules, state) {
        var userRoles = Object.keys(state.user.roles); // convert roles object to array
        userRoles = userRoles.map(Number);

        // if there are no restrictions
        if (!accessRules) {
            return true;
        }
        
        // if the user is a super user
        if (userRoles.includes(1)){
            return true;
        }

        // if there are required roles
        if (accessRules.roles) {
            if (userRoles.some(r => accessRules.roles.indexOf(r) >= 0)) {
                return true;
            }
        }
        
        // if staff is required
        if (accessRules.isStaff){
            if (state.user.userTypeID == 4) {
                return true;
            }
        }
        
        // if logged in is required
        if (accessRules.loggedIn) {
            if (state.loggedIn) {
                return true;
            }
        }

        return false;
    },
    
    nestedSet: function (obj, path, value) {
        let schema = obj;  // a moving reference to internal objects within obj
        let pList = path.split('.');
        let len = pList.length;
        for (let i = 0; i < len - 1; i++) {
            let elem = pList[i];
            if (!schema[elem]) {
                schema[elem] = {}
            }
            schema = schema[elem];
        }
    
        schema[pList[len-1]] = value;
    },

    /** @see https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator */
    getUserBrowser: function () {
        var userAgent = navigator.userAgent;
        var browser = null;

        if (userAgent.indexOf("Firefox") > -1) {
            browser = "Mozilla Firefox";
        } else if (userAgent.indexOf("SamsungBrowser") > -1) {
            browser = "Samsung Internet";
        } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
            browser = "Opera";
        } else if (userAgent.indexOf("Trident") > -1) {
            browser = "Microsoft Internet Explorer";
        } else if (userAgent.indexOf("Edge") > -1) {
            browser = "Microsoft Edge";
        } else if (userAgent.indexOf("Chrome") > -1) {
            browser = "Google Chrome or Chromium";
        } else if (userAgent.indexOf("Safari") > -1) {
            browser = "Apple Safari";
        } else {
            browser = "unknown";
        }

        return browser;
    },
    
    capitalizeFirstLetter: function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
}