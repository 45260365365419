import helpers from "@/store/helpers";
import { cdiVars } from "@/cdiVars";
import http from "axios";
import appStore from "@/store/App.store";

export const massFileUploadStore = {
    baseUrl: process.env.VUE_APP_URL,
    state: {
        file: null,
        progress: 0,
        fileID: null,
        fileTypeID: cdiVars.FILE_TYPE_UNASSIGNED_FL,
        referenceID: null,
        reference2ID: null,
        conformingTypeID: null,
        fileTitle: null,
        fileName: null,
        allowAttach: true,
        errors: [],
        warnings: [],
        isModal: false,
        massUploadFilePresent: false
    },
    massUpload: function (curbsToUpdate) {
        let formData = new FormData();
        formData.append('files[]', this.state.file);
        formData.append('curbs_to_update', curbsToUpdate);
        formData.append('cdi_file_type_id', this.state.fileTypeID);
        formData.append('conforming_type_id', this.state.conformingTypeID);
        let fn = appStore.state.fileMassUploadCallback;

        http.post(`${ this.baseUrl }/api/cdi_file/mass_upload`, formData,
            {headers: helpers.authHeader()}).then(result => {
                switch (result.data.opt) {
                    case 'error':
                        appStore.errorBox(result.data.msg);
                        break;
                    case 'warning':
                        appStore.warningBox(result.data.msg);
                        break;
                    default:
                        appStore.successBox('File Attached');
                        break;
                }

                if (typeof fn === 'function') {
                    fn();
                }
                appStore.state.synchronizeFileCallbackView = true;

                this.resetData();
            });
    },
    setFile: function (file) {
        this.state.file = file;
    },
    setTitle: function (title) {
        this.state.fileTitle = title;
    },
    initialize: function (fileTypeID = cdiVars.FILE_TYPE_UNASSIGNED_FL) {
        this.state.fileTypeID = fileTypeID;
        this.state.referenceID = appStore.state.fileUploadContextData.referenceID;
        this.state.reference2ID = appStore.state.fileUploadContextData.reference2ID;
        this.state.conformingTypeID = appStore.state.fileUploadContextData.conformingTypeID;
    },
    resetData: function (keepFileData = false) {
        if (!keepFileData) {
            this.state.file = null;
            this.state.progress = 0;
            this.state.fileID = null;
            this.state.fileTitle = null;
            this.state.fileName = null;
        }

        this.state.allowAttach = true;

        this.state.fileTypeID = cdiVars.FILE_TYPE_UNASSIGNED_FL;
        this.state.referenceID = 0;
        this.state.reference2ID = 0;
        this.state.conformingTypeID = 0;

        appStore.setFileMassUploadContext(null);
        appStore.setFileUploadContextData({
            referenceID: 0,
            reference2ID: 0,
            data: {},
            metadata: {},
            conformingTypeID: 0,
        });
        appStore.setFileUploadCallback(function () {});
    },
    loadMetadata: function () {
        let queryString = helpers.getQSString(this.options());
        http.post(`${ this.baseUrl }/api/cdi_file/get_metadata`, queryString, {headers: helpers.authHeader()}).then(result => {
            appStore.setFileUploadContextData({
                referenceID: this.state.referenceID,
                reference2ID: this.state.reference2ID,
                metadata: result.data
            });
        });
    }
}