import { cdiVars } from '@/cdiVars';
export default {
    PAGES: {
        //Data pages
        data: {
            asset: {
                label: 'Asset',
                value: '/asset',
                roles: [cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_LEADERSHIP]
            },
            cdi_location: {
                label: 'CDI Locations',
                value: '/cdi_location',
                roles: [cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_LOGISTICS]
            },
            company: {
                label: 'Companies',
                value: '/company',
                roles: [cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_MANAGER, cdiVars.CDI_ROLE_LOGISTICS]
            },
            credit: {
                label: 'Credits',
                value: '/credit',
                roles: [cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_LEADERSHIP]
            },
            drawings: { 
                label: 'Drawings', 
                value: '/drawings',
                isStaff: true,
            },
            cdi_file: { 
                label: 'Files', 
                value: '/cdi_file',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_LEADERSHIP ] 
            },
            curb: { 
                label: 'Items', 
                value: '/curb', 
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_LEADERSHIP ] 
            },
            tracker_mer: { 
                label: 'MERs', 
                value: '/tracker_mer', 
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_QC, cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_ENG ] 
            },
            model: { 
                label: 'Model', 
                value: '/model', 
                roles: [ cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_INSIDE_SALES ] 
            },
            tracker: { 
                label: 'Orders', 
                value: '/tracker', 
                isStaff: true
            },
            part: {
                label: 'Part',
                value: '/part',
                roles: [ cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_QC, cdiVars.CDI_ROLE_INSIDE_SALES ] 
            },
            payments: { 
                label: 'Payments', 
                value: '/payment',
                roles: [ cdiVars.CDI_ROLE_ACCOUNTING ] 
            },
            user: {
                label: 'People',
                value: '/user',
                roles: [ cdiVars.CDI_ROLE_HR, cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_LOGISTICS, cdiVars.CDI_ROLE_LEADERSHIP ]
            },
            po: {
                label: 'POs',
                value: '/po',
                roles: [ cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_MATERIAL ] 
            },
            corpay_po: {
                label: 'POs (Corpay)',
                value: '/corpay_po',
                roles: [ cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_MATERIAL ]
            },
            pricing: {
                label: 'Pricing',
                value: '/pricing',
                roles: [ cdiVars.CDI_ROLE_LEADERSHIP ] 
            },
            material: { 
                label: 'Purchased Items',
                value: '/material',
                roles: [ cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_MATERIAL, cdiVars.CDI_ROLE_LEADERSHIP ] 
            },
            quote: {
                label: 'Quotes',
                value: '/quote',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_LOGISTICS ] 
            },
            region: {
                label: 'Regions',
                value: '/region',
                roles: [ cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_LOGISTICS ] 
            },
            shipper: {
                label: 'Shippers',
                value: '/shipper',
                roles: [ cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_LOGISTICS ] 
            },
            site_request: {
                label: 'Site Requests',
                value: '/site_request',
                roles: [ cdiVars.CDI_ROLE_HR, cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_QC, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_LOGISTICS, cdiVars.CDI_ROLE_MATERIAL ]
            },
            supplier: {
                label: 'Suppliers',
                value: '/supplier',
                roles: [ cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_LOGISTICS ]
            },
            territory: {
                label: 'Territories',
                value: '/territory',
                roles: [cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_INSIDE_SALES]
            },
            timecard_rpt: {
                label: 'Timecards',
                value: '/rpt/timecard_rpt',
                roles: [ cdiVars.CDI_ROLE_HR, cdiVars.CDI_ROLE_MANAGER, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_ACCOUNTING ] 
            }
        },
        // Tools Pages
        tools: {
            cdi_announcements: {
                label: 'CDI Announcements',
                href: `${process.env.VUE_APP_URL}/cdi_announcements.php`,
                isStaff: true
            },
            catalog_order: {
                label: 'Catalog Order',
                value: '/catalog_order',
                roles: [cdiVars.CDI_ROLE_SALES],
            },
            cogs_config: {
                label: 'Cost of Goods Configuration',
                value: '/cogs_config',
                roles: [cdiVars.CDI_ROLE_LEADERSHIP]
            },
            invoicing: {
                label: 'Invoicing',
                value: '/invoicing',
                roles: [cdiVars.CDI_ROLE_ACCOUNTING]
            },
            sales_goals: {
                label: 'Sales And Order Goals',
                value: '/sales_goals',
                isStaff: true
            },
            inventory_manager: {
                label: 'Inventory Manager',
                value:'/inventory_manager',
                roles: [cdiVars.CDI_ROLE_LOGISTICS, cdiVars.CDI_ROLE_BATCHING, cdiVars.CDI_ROLE_ENG]
            },
            conforming_stamp: {
                label: 'Conforming Stamp Manager',
                value:'/conforming_stamp',
                roles: [ cdiVars.CDI_ROLE_ENG ]
            },
            maintenance_log: {
                label: 'Maintenance Log',
                value:'/maintenance_log',
                isStaff: true,
            },
            spring_configuration: {
                label: 'Iso Springs',
                value: '/spring_configuration',
                roles: [ cdiVars.CDI_ROLE_ENG ]
            },
            po_receiving: { 
                label: 'PO Receiving', 
                value: '/po_receiving', 
                roles: [ cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_SHOP_SUP ] 
            },
            directory: {
                label: 'Directory',
                value: '/directory',
            },
            scrap_tracker: { 
                label: 'Scrap Tracker',
                value: '/scrap_tracker',
                roles: [ cdiVars.CDI_ROLE_MANAGER, cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_SHOP_SUP ] 
            },
            consolidation: { 
                label: 'Ship Consolidate', 
                value: '/consolidation', 
                roles: [ cdiVars.CDI_ROLE_LOGISTICS ] 
            },
            ship_manifest: { 
                label: 'Shipping Manifest', 
                value: '/rpt/ship_manifest', 
                roles: [ cdiVars.CDI_ROLE_LOGISTICS ] 
            },
            ship_paid: { 
                label: 'Shipping Payments', 
                value: '/rpt/ship_paid', 
                roles: [ cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_LOGISTICS ] 
            },
            status_board: { 
                label: 'Status Board',
                value: '/status_board',
                isStaff: true 
            },
            surcharge: {
                label: 'Surcharge Rules',
                value: '/surcharge',
                roles: [ cdiVars.CDI_ROLE_HR, cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_QC, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_LOGISTICS, cdiVars.CDI_ROLE_MATERIAL ]
            },
            supply_return: { 
                label: 'Supply/Return',
                value: '/SRPdf',
                target: '_blank',
                href:`${process.env.VUE_APP_URL}/SR_pdf.php`,
                isStaff: true 
            },
            warehouse_order: {
                label: 'Warehouse Order',
                value: '/warehouse_order',
                roles: [ cdiVars.CDI_ROLE_SHOP_SUP ] 
            },
            config: {
                label: 'Website Configuration',
                value: '/config',
                roles: [cdiVars.CDI_ROLE_SUPER, cdiVars.CDI_ROLE_LEADERSHIP]
            },
            development_tools: {
                label: 'Development Tools',
                value: '/dev_tools',
                roles: [cdiVars.CDI_ROLE_SUPER]
            },
            qrscanner: {
                label: 'QR Scanner',
                value: '/qrscanner',
            }
        },
        // Scheduler Pages
        scheduler: {
            drawsch: {
                label: 'Drawing',
                value: '/drawsch',
                roles: [cdiVars.CDI_ROLE_HR, cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_QC, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_LOGISTICS, cdiVars.CDI_ROLE_MATERIAL]
            },
            maintenance_scheduler: {
                label: 'Maintenance',
                value: '/maintenance_scheduler',
                isStaff: true
            },
            measure_scheduler: {
                label: 'Measurement',
                value: '/measure_scheduler',
                isStaff: true
            },
            divider1: {
                divider: true
            },
            master: { 
                label: 'Master',
                value: '/buildsch/master',
                isStaff: true 
            },
            divider2: {
                divider: true
            },
            sales: { 
                label: 'Sales Checking',
                value: '/buildsch/sales',
                isStaff: true 
            },
            materials: { 
                label: 'Special Materials',
                value: '/buildsch/materials',
                isStaff: true 
            },
            engineering: { 
                label: 'Engineering',
                value: '/buildsch/engineering',
                isStaff: true 
            },
            checking: { 
                label: 'Eng Checking',
                value: '/buildsch/checking',
                isStaff: true 
            },
            radan: { 
                label: 'Radan', 
                value: '/buildsch/radan',
                isStaff: true 
            },
            batching: { 
                label: 'Batching',
                value: '/buildsch/batching',
                isStaff: true 
            },
            divider3: {
                divider: true
            },
            turret: { 
                label: 'Turret',
                value: '/buildsch/turret',
                isStaff: true 
            },
            folding: { 
                label: 'Folding', 
                value: '/buildsch/folding',
                isStaff: true 
            },
            clinching: { 
                label: 'Clinching', 
                value: '/buildsch/clinching',
                isStaff: true 
            },
            shell: { 
                label: 'Shell',
                value: '/buildsch/shell',
                isStaff: true 
            },
            guts: { 
                label: 'Guts', 
                value: '/buildsch/guts',
                isStaff: true 
            },
            qc: { 
                label: 'Quality Control',
                value: '/buildsch/qc',
                isStaff: true 
            },
            insulate: { 
                label: 'Insulate', 
                value: '/buildsch/insulate',
                isStaff: true 
            },
            crate: { 
                label: 'Crate',
                value: '/buildsch/crate',
                isStaff: true 
            },
            divider4: {
                divider: true
            },
            loading: {
                label: 'Loading',
                value: '/buildsch/loading',
                isStaff: true
            },
            finalpaperwork: {
                label: 'Final Paperwork',
                value: '/buildsch/finalpaperwork',
                isStaff: true 
            },
        },
        // Report Pages
        reports: {
            accounting_header: {
                label: 'ACCOUNTING',
                roles: [
                    cdiVars.CDI_ROLE_ACCOUNTING,
                    cdiVars.CDI_ROLE_LEADERSHIP,
                    cdiVars.CDI_ROLE_SHOP_SUP,
                    cdiVars.CDI_ROLE_MANAGER,
                ]
            },
            aging: {
                label: 'AR Aging',
                value: '/rpt/aging',
                roles: [cdiVars.CDI_ROLE_ACCOUNTING]
            },
            automation_log: {
                label: 'AR Automation Log',
                value: '/rpt/automation_log',
                roles: [cdiVars.CDI_ROLE_ACCOUNTING]
            },
            credit_rpt: {
                label: 'Credits',
                value: '/rpt/credit_rpt',
                roles: [ cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_LEADERSHIP ]
            },
            fifo_rpt: {
                label: 'FIFO Inventory Value',
                value: '/rpt/fifo_rpt',
                roles: [cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_LEADERSHIP]
            },
            payment_rpt: {
                label: 'Payments',
                value: '/rpt/payment_rpt',
                roles: [ cdiVars.CDI_ROLE_ACCOUNTING ]
            },
            purchase_order_rpt: {
                label: 'Purchase Orders',
                value: '/rpt/purchase_order_rpt',
                roles: [ cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_SHOP_SUP ]
            },
            scrap_tracking: {
                label: 'Scrap Tracking',
                value: '/rpt/scrap_tracking',
                roles: [ cdiVars.CDI_ROLE_MANAGER, cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_SHOP_SUP ]
            },
            taxable: {
                label: 'Taxable Sales',
                value: '/rpt/taxable',
                roles: [ cdiVars.CDI_ROLE_ACCOUNTING ]
            },
            engineering_header: {
                label: 'ENGINEERING',
                roles: [
                    cdiVars.CDI_ROLE_SALES,
                    cdiVars.CDI_ROLE_SHOP_SUP,
                    cdiVars.CDI_ROLE_MANAGER,
                    cdiVars.CDI_ROLE_ENG,
                ]
            },
            drawing_requests: {
                label: 'Drawing Requests',
                value: '/rpt/drawing_requests',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_MANAGER ]
            },
            people_header: {
                label: 'PEOPLE',
                roles: [
                    cdiVars.CDI_ROLE_HR,
                    cdiVars.CDI_ROLE_LEADERSHIP,
                    cdiVars.CDI_ROLE_MANAGER,
                    cdiVars.CDI_ROLE_SHOP_SUP,
                    cdiVars.CDI_ROLE_ACCOUNTING,
                    cdiVars.CDI_ROLE_SALES,
                    cdiVars.CDI_ROLE_ENG,
                    cdiVars.CDI_ROLE_LOGISTICS,
                    cdiVars.CDI_ROLE_QC
                ]
            },
            attendance: {
                label: 'Attendance',
                value: '/rpt/attendance',
                roles: [cdiVars.CDI_ROLE_HR, cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_MANAGER, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_ACCOUNTING]
            },
            clock_in_out_ips: {
                label: 'Clock In/Out IPs',
                value: '/rpt/clock_in_out_ips',
                roles: [ cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_HR ]
            },
            employee_photos: {
                label: 'Employee Photos',
                value: '/rpt/employee_photos',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_LOGISTICS ]
            },
            first_clock_in: {
                label: 'First Clock In Time',
                value: '/rpt/first_clock_in',
                roles: [ cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_HR, cdiVars.CDI_ROLE_SHOP_SUP ]
            },
            hours: {
                label: 'Hours',
                value: '/rpt/hours',
                roles: [ cdiVars.CDI_ROLE_HR, cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_MANAGER, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_ACCOUNTING ]
            },
            morale: {
                label: 'Morale',
                value: '/rpt/morale',
                roles: [ cdiVars.CDI_ROLE_LEADERSHIP ]
            },
            user_rpt: {
                label: 'People',
                value: '/rpt/user_rpt',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_LOGISTICS, cdiVars.CDI_ROLE_MANAGER, cdiVars.CDI_ROLE_LEADERSHIP ]
            },
            recoginition: {
                label: 'Recognition',
                value: '/rpt/recognition',
                roles: [ cdiVars.CDI_ROLE_HR, cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_QC, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_LOGISTICS ]
            },
            timecard_current: {
                label: 'Timecards Current',
                value: '/rpt/timecard_current',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_LOGISTICS ]
            },
            inventory_header: {
                label: 'INVENTORY',
                roles: [
                    cdiVars.CDI_ROLE_ACCOUNTING,
                    cdiVars.CDI_ROLE_SHOP_SUP,
                    cdiVars.CDI_ROLE_LEADERSHIP,
                    cdiVars.CDI_ROLE_LOGISTICS,
                    cdiVars.CDI_ROLE_MANAGER
                ]
            },
            asset_rpt: {
                label: 'Assets',
                value: '/rpt/asset_rpt',
                roles: [cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_LEADERSHIP]
            },
            maintenance: {
                label: 'Maintenance',
                value: '/rpt/maintenance',
                roles: [ cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_LEADERSHIP ]
            },
            raw_material_inventory: {
                label: 'Material Inventory',
                value: '/rpt/raw_material_inventory',
                roles: [ cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_LOGISTICS ]
            },
            stock: {
                label: 'Stock',
                value: '/rpt/stock',
                roles: [ cdiVars.CDI_ROLE_MANAGER, cdiVars.CDI_ROLE_LEADERSHIP ]
            },
            stock_movement: {
                label: 'Stock Movement',
                value: '/rpt/stock_movement',
                roles: [ cdiVars.CDI_ROLE_MANAGER, cdiVars.CDI_ROLE_LEADERSHIP ]
            },
            leadership_header: {
                label: 'LEADERSHIP',
                roles: [
                    cdiVars.CDI_ROLE_LEADERSHIP,
                    cdiVars.CDI_ROLE_SHOP_SUP,
                    cdiVars.CDI_ROLE_MANAGER
                ]
            },
            scorecard: {
                label: 'Scorecard',
                value: '/rpt/scorecard',
                roles: [ cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_MANAGER ]
            },
            logistics_header: {
                label: 'LOGISTICS',
                roles: [
                    cdiVars.CDI_ROLE_LEADERSHIP,
                    cdiVars.CDI_ROLE_SHOP_SUP,
                    cdiVars.CDI_ROLE_MANAGER,
                    cdiVars.CDI_ROLE_ACCOUNTING,
                    cdiVars.CDI_ROLE_LEADERSHIP,
                    cdiVars.CDI_ROLE_LOGISTICS,
                    cdiVars.CDI_ROLE_INSIDE_SALES,
                    cdiVars.CDI_ROLE_SALES,
                ]
            },
            callahead: {
                label: 'Call Aheads',
                value: '/rpt/callahead',
                roles: [ cdiVars.CDI_ROLE_LOGISTICS, cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_INSIDE_SALES ]
            },
            deliveries: {
                label: 'Deliveries',
                value: '/rpt/deliveries',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_LOGISTICS ]
            },
            freight: {
                label: 'Freight Accrual',
                value: '/rpt/freight',
                roles: [ cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_LOGISTICS ]
            },
            item_freight: {
                label: 'Item Freight',
                value: '/rpt/item_freight',
                roles: [ cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_LOGISTICS ]
            },
            delivery_ontime: {
                label: 'On Time',
                value: '/rpt/delivery_ontime',
                roles: [ cdiVars.CDI_ROLE_LOGISTICS, cdiVars.CDI_ROLE_MANAGER, cdiVars.CDI_ROLE_LEADERSHIP ]
            },
            shipping: {
                label: 'Shipping',
                value: '/rpt/shipping',
                roles: [ cdiVars.CDI_ROLE_LOGISTICS, cdiVars.CDI_ROLE_MANAGER, cdiVars.CDI_ROLE_INSIDE_SALES, cdiVars.CDI_ROLE_SALES ]
            },
            production_header: {
                label: 'PRODUCTION',
                roles: [
                    cdiVars.CDI_ROLE_HR,
                    cdiVars.CDI_ROLE_SALES,
                    cdiVars.CDI_ROLE_ACCOUNTING,
                    cdiVars.CDI_ROLE_ENG,
                    cdiVars.CDI_ROLE_LEADERSHIP,
                    cdiVars.CDI_ROLE_LOGISTICS,
                    cdiVars.CDI_ROLE_SHOP_SUP,
                    cdiVars.CDI_ROLE_MANAGER
                ]
            },
            batch_utilization: {
                label: 'Batch Utilization',
                value: '/rpt/batch_utilization',
                roles: [ cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_LEADERSHIP ]
            },
            build_progress: {
                label: 'Build Progress (In Development)',
                value: '/rpt/build_progress',
                roles: [cdiVars.CDI_ROLE_LOGISTICS, cdiVars.CDI_ROLE_MANAGER, cdiVars.CDI_ROLE_INSIDE_SALES, cdiVars.CDI_ROLE_SALES]
            },
            daily_curb_count: {
                label: 'Daily Curb Count',
                value: '/rpt/daily_curb_count',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_LOGISTICS ]
            },
            jobcosting: {
                label: 'Job Costing',
                value: '/rpt/jobcosting',
                roles: [ cdiVars.CDI_ROLE_HR, cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_LOGISTICS ]
            },
            labor: {
                label: 'Labor Rate',
                value: '/rpt/labor',
                roles: [ cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_MANAGER, cdiVars.CDI_ROLE_LEADERSHIP ]
            },
            labor_rate: {
                label: 'Labor Rate (Sales)',
                value: '/rpt/labor_rate',
                roles: [ cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_MANAGER, cdiVars.CDI_ROLE_LEADERSHIP ]
            },
            production_sales_value: {
                label: 'Production Sales Value',
                value: '/rpt/production_sales_value',
                roles: [ cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_MANAGER, cdiVars.CDI_ROLE_LEADERSHIP ]
            },
            rework: {
                label: 'Re-batched Items',
                value: '/rpt/rework',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_MANAGER ]
            },
            job: {
                label: 'Shop (Job) Tasks',
                value: '/rpt/job',
                roles: [ cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_MANAGER ]
            },
            shoptime: {
                label: 'Shop Time',
                value: '/rpt/shoptime',
                roles: [ cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_MANAGER ]
            },
            wip_value: {
                label: 'WIP Value',
                value: '/rpt/wip_value',
                roles: [ cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_LEADERSHIP ]
            },
            sales_header: {
                label: 'SALES',
                roles: [
                    cdiVars.CDI_ROLE_SALES,
                    cdiVars.CDI_ROLE_ACCOUNTING,
                    cdiVars.CDI_ROLE_QC,
                    cdiVars.CDI_ROLE_LEADERSHIP,
                    cdiVars.CDI_ROLE_ENG,
                    cdiVars.CDI_ROLE_MANAGER,
                    cdiVars.CDI_ROLE_INSIDE_SALES,
                    cdiVars.CDI_ROLE_SHOP_SUP,
                    cdiVars.CDI_ROLE_LOGISTICS
                ]
            },
            daily_invoiced: {
                label: 'Sales Progress',
                value: '/rpt/daily_invoiced',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_MANAGER ]
            },
            sales_scorecard: {
                label: 'Sales Scorecard',
                value: '/rpt/sales_scorecard',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_MANAGER ]
            },
            customer_summary: {
                label: 'Customer Summary',
                value: '/rpt/customer_summary',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_INSIDE_SALES, cdiVars.CDI_ROLE_LEADERSHIP ]
            },
            sales_goals: {
                label: 'Order Progress',
                value: '/rpt/order_progress',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING ]
            },
            sales: {
                label: 'Sales',
                value: '/rpt/sales',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_LOGISTICS, cdiVars.CDI_ROLE_MANAGER, cdiVars.CDI_ROLE_LEADERSHIP ]
            },
            sales_misc_header: {
                label: 'SALES MISCELLANEOUS',
                roles: [
                    cdiVars.CDI_ROLE_SALES,
                    cdiVars.CDI_ROLE_ACCOUNTING,
                    cdiVars.CDI_ROLE_QC,
                    cdiVars.CDI_ROLE_LEADERSHIP,
                    cdiVars.CDI_ROLE_ENG,
                    cdiVars.CDI_ROLE_MANAGER,
                    cdiVars.CDI_ROLE_INSIDE_SALES,
                    cdiVars.CDI_ROLE_SHOP_SUP,
                    cdiVars.CDI_ROLE_LOGISTICS
                ]
            },
            brand_sales: {
                label: 'Brand Sales',
                value: '/rpt/brand_sales',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_MANAGER, cdiVars.CDI_ROLE_LEADERSHIP ]
            },
            crm_rpt: {
                label: 'Communications',
                value: '/rpt/crm_rpt',
                roles: [ cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_INSIDE_SALES, cdiVars.CDI_ROLE_SALES ]
            },
            curb_sales: {
                label: 'Item Sales',
                value: '/rpt/curb_sales',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_MANAGER ] 
            },
            curbs_shipped: {
                label: 'Items Shipped',
                value: '/rpt/curbs_shipped',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_MANAGER, cdiVars.CDI_ROLE_LEADERSHIP ] 
            },
            tracker_mer_rpt: {
                label: 'MERs',
                value: '/rpt/tracker_mer_rpt',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_QC, cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_ENG ] 
            },
            quote_rpt: {
                label: 'Quotes',
                value: '/rpt/quote_rpt',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_LEADERSHIP ] 
            },
            remake: {
                label: 'Remake',
                value: '/rpt/remake',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_MANAGER ] 
            },
            /*sales_revenue: {
                label: 'Sales Revenue',
                value: '/rpt/sales_revenue',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ENG, cdiVars.CDI_ROLE_SHOP_SUP, cdiVars.CDI_ROLE_LOGISTICS ] 
            },*/
            sales_rebate: {
                label: 'Sales Rebate',
                value: '/rpt/sales_rebate',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING ]
            },
            sales_rebate_companies: {
                label: 'Sales Rebate Companies',
                value: '/rpt/sales_rebate_companies',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING ]
            },
            sales_incentive: {
                label: 'Sales Incentive',
                value: '/rpt/sales_incentive',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING ]
            },
            unverified_quotes_rpt: {
                label: 'Unverified Orders',
                value: '/rpt/unverified_quotes_rpt',
                roles: [ cdiVars.CDI_ROLE_SALES, cdiVars.CDI_ROLE_ACCOUNTING, cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_ENG ] 
            },
        },

    }
}
