import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import helpers from '@/store/helpers';
import appStore from '@/store/App.store';
//import { cdiVars } from '@/cdiVars'

Vue.use(VueRouter);

const routes = [
    //about paths
    {
        path: '/api/*',
        name: 'content',
    },
    {
        path: '/service-worker.js',
        name: 'content',
    },
    {
        path: '/rapi/*',
        name: 'content',
    },
    {
        path: '/healthcheck.html',
        name: 'content'
    },
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: { redirectToMy: true }
    },
    {
        path: '/asset/:id?',
        name: 'asset',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'Assets' }
    },
    {
        path: '/branch/:id',
        name: 'branch',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'Branches' }
    },
    {
        path: '/company/:id?',
        name: 'company',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'Companies' }
    },
    {
        path: '/cdi_file/:id?',
        name: 'cdi_file',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: {access: { isStaff: true }, title: 'Files' }
    },
    {
        path: '/cdi_location/:id?',
        name: 'cdi_location',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: {access: { isStaff: true }, title: 'Locations' }
    },
    {
        path: '/territory/:id?',
        name: 'territory',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: {access: { isStaff: true }, title: 'Locations' }
    },
    {
        path: '/curb/:id?',
        name: 'curb',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'Items' }
    },
    {
        path: '/material/:id?',
        name: 'material',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: {access: {isStaff: true}, title: 'Materials'}
    },
    {
        path: '/credit/:id?',
        name: 'credit',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: {access: { isStaff: true }, title: 'Credits' }
    },
    {
        path: '/model/:id?',
        name: 'model',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'Models' }
    },
    {
        path: '/part/:id?',
        name: 'part',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'Parts' }
    },
    {
        path: '/payment/:id?',
        name: 'payment',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'Payments' }
    },
    {
        path: '/po/:id?',
        name: 'po',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'Purchase Orders' }
    },
    {
        path: '/corpay_po/:id?',
        name: 'corpay_po',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'Purchase Orders' }
    },
    {
        path: '/pricing',
        name: 'pricing',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'Pricing' }
    },
    {
        path: '/quote/:id?',
        name: 'quote',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { title: 'Quotes' }
    },
    {
        path: '/shipper/:id?',
        name: 'shipper',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'Shippers' }
    },
    {
        path: '/site_request/:id?',
        name: 'site_request',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'Site Requests' }
    },
    {
        path: '/supplier/:id?',
        name: 'supplier',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'Suppliers' }
    },
    {
        path: '/spring_configuration/:id?',
        name: 'spring_configuration',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'Spring Configurations' }
    },
    {
        path: '/maintenance_log',
        name: 'maintenance_log',
        component: () => import(/* webpackChunkName: "tools" */ '../views/BusinessObjectPage'),
        props: true,
        meta: { access: { isStaff: true }, title: 'Maintenance Log', fullWidth: true }
    },
    {

        path: '/timecard/:id?',
        name: 'timecard',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true } }
    },
    {
        path: '/tracker/:id?',
        name: 'tracker',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: {access: {isStaff: true}, title: 'Orders'}
    },
    {
        path: '/tracker_mer/:id?',
        name: 'tracker_mer',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'MERs' }
    },
    {
        path: '/user/:id?',
        name: 'user',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'People' }
    },
    {
        path: '/rpt/:reportName',
        name: 'reports',
        component: () => import(/* webpackChunkName: "rpt" */ '../views/ReportPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, fullWidth: true }
    },
    //about paths
    {
        path: '/about',
        alias: [
            '/aboutval',
            '/adapters',
            '/credit_app',
            '/conforming',
            '/customcurbs',
            '/ductcal',
            '/employment',
            '/engineering',
            '/field_measure',
            '/guarantee',
            '/install_instructions',
            '/isolation',
            '/locations',
            '/mobile',
            '/products',
            '/shipping',
            '/territory',
            '/tools',
            '/new_user_r',
        ],
        name: 'content',
        component: () => import(/* webpackChunkName: "tools" */ '../views/ContentPage.vue'),
        meta: { redirectToMy: true }
    },
    {
        path: '/termsandconditions',
        name: 'termsAndConditions',
        component: () => import(/* webpackChunkName: "termsAndConditions" */ '../views/ContentPage.vue'),
        meta: { redirectToMy: true }
    },
    {
        path: '/privacypolicy',
        name: 'privacyPolicy',
        component: () => import(/* webpackChunkName: "privacyPolicy" */ '../views/ContentPage.vue'),
        meta: { redirectToMy: true }
    },
    {
        path: '/buildsch/:taskName?',
        name: 'buildsch',
        component: () => import(/* webpackChunkName: "buildsch" */ '../views/BuildSchedulerPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, fullWidth: true }
    },
    {
        path: '/catalog_order',
        name: 'catalog_order',
        component: () => import(/* webpackChunkName: "tools" */ '../views/ToolsPage.vue'),
        meta: { access: { isStaff: true }, title: 'Catalog Order' }
    },
    {
        path: '/cogs_config',
        name: 'cogs_config',
        component: () => import(/* webpackChunkName: "tools" */ '../views/ToolsPage.vue'),
        meta: { access: { isStaff: true }, title: 'Cost of Goods Configuration' }
    },
    {
        path: '/surcharge',
        name: 'surcharge',
        component: () => import(/* webpackChunkName: "tools" */ '../views/ToolsPage.vue'),
        meta: { access: { isStaff: true }, title: 'Surcharge' }
    },
    {
        path: '/sales_goals',
        name: 'sales_goals',
        component: () => import(/* webpackChunkName: "tools" */ '../views/ToolsPage.vue'),
        meta: { access: { isStaff: true }, title: 'Order Progress' }
    },
    {
        path: '/clock',
        name: 'clock',
        component: () => import(/* webpackChunkName: "tools" */ '../views/ToolsPage.vue'),
        meta: { access: { isStaff: true }, title: 'Timeclock' }
    },
    {
        path: '/consolidation',
        name: 'consolidation',
        component: () => import(/* webpackChunkName: "tools" */ '../views/ToolsPage.vue'),
        meta: { access: { isStaff: true }, title: 'Shipping Consolidation' }
    },
    {
        path: '/invoicing',
        name: 'invoicing',
        component: () => import(/* webpackChunkName: "tools" */ '../views/ToolsPage.vue'),
    },
    {
        path: '/drawsch/',
        name: 'drawsch',
        component: () => import(/* webpackChunkName: "buildsch" */ '../views/DrawingSchedulerPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, fullWidth: true, title: 'Drawing Scheduler' }
    },
    {
        path: '/maintenance_scheduler',
        name: 'maintenance_scheduler',
        component: () => import(/* webpackChunkName: "maintenance_scheduler" */ '../views/MaintenanceSchedulerPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, fullWidth: true, title: 'Maintenance Scheduler' }
    },
    {
        path: '/measure_scheduler',
        name: 'measure_scheduler',
        component: () => import(/* webpackChunkName: "maintenance_scheduler" */ '../views/MeasureSchedulerPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, fullWidth: true, title: 'Measure Scheduler' }
    },
    {
        path: '/po_receiving',
        name: 'po_receiving',
        component: () => import(/* webpackChunkName: "tools" */ '../views/ToolsPage.vue'),
        meta: { access: { isStaff: true }, title: 'PO Receiving' }
    },
    {
        path: '/directory',
        name: 'directory',
        component: () => import(/* webpackChunkName: "tools" */ '../views/ContentPage.vue'),
        meta: { title: 'Directory' }
    },
    {
        path: '/inventory_manager',
        name: 'inventory_manager',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'Inventory Manager' }
    },
    {
        path: '/conforming_stamp/:id?',
        name: 'conforming_stamp',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'Conforming Manager' }
    },
    {
        path: '/scrap_tracker',
        name: 'scrap_tracker',
        component: () => import(/* webpackChunkName: "tools" */ '../views/ToolsPage.vue'),
        meta: { access: { isStaff: true }, title: 'Scrap Tracker' }
    },
    {
        path: '/status_board',
        name: 'status_board',
        component: () => import(/* webpackChunkName: "tools" */ '../views/ToolsPage.vue'),
        meta: { access: { isStaff: true }, title: 'Status Board' }
    },
    {
        path: '/warehouse_order',
        name: 'warehouse_order',
        component: () => import(/* webpackChunkName: "tools" */ '../views/ToolsPage.vue'),
        meta: { access: { isStaff: true }, title: 'Warehouse Order' }
    },
    {
        path: '/region',
        name: 'region',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        meta: { access: { isStaff: true }, title: 'Regions' }
    },
    {
        path: '/sign_in',
        name: 'signIn',
        component: () => import(/* webpackChunkName: "asset" */ '../views/SignInPage.vue'),
        meta: { title: 'Sign In' }
    },
    {
        path: '/my',
        name: 'myCdi',
        component: () => import(/* webpackChunkName: "my" */ '../views/MyPage.vue'),
        meta: { title: 'My CDI' }
    },
    {
        path: '/my/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "register" */ '../views/MyPage.vue'),
        meta: { redirectToMy: true }
    },
    {
        path: '/myedit',
        name: 'myEdit',
        component: () => import(/* webpackChunkName: "myEdit" */ '../views/MyPage.vue'),
        meta: { title: 'My CDI Edit' }
    },
    {
        path: '/myalerts',
        name: 'myAlerts',
        component: () => import(/* webpackChunkName: "myAlerts" */ '../views/MyPage.vue'),
        meta: { title: 'My Alerts' }
    },
    {
        path: '/pass_reset',
        name: 'passwordReset',
        component: () => import(/* webpackChunkName: "passwordReset" */ '../views/MyPage.vue'),
    },
    {
        path: '/drawings',
        name: 'drawings',
        component: () => import(/* webpackChunkName: "drawings" */ '../views/MyPage.vue'),
        meta: { title: 'Drawings' }
    },
    {
        path: '/myorder/:id?',
        name: 'myOrder',
        component: () => import(/* webpackChunkName: "myOrder" */ '../views/MyPage.vue'),
        props: true
    },
    {
        path: '/error',
        name: 'error',
        component: () => import(/* webpackChunkName: "asset" */ '../views/ErrorPage.vue'),
        meta: { title: '404 Not Found' }
    },
    {
        path: '/download/:id',
        name: 'download',
        component: () => import(/* webpackChunkName: "rpt" */ '../views/DownloadPage'),
        props: true,
    },
    {
        path: '/dev_tools',
        name: 'developmentTools',
        component: () => import(/* webpackChunkName: "tools" */ '../views/ToolsPage.vue'),
        props: true,
        meta: { access: { isStaff: true }, title: 'Development Tools', fullWidth: true }
    },
    {
        path: '/config',
        name: 'config',
        component: () => import(/* webpackChunkName: "tools" */ '../views/ToolsPage.vue'),
        meta: { access: { isStaff: true }, title: 'Website Configuration' }
    },
    {
        path: '/cogs_upload',
        name: 'cogs_upload',
        props: true,
        meta: { access: {isStaff: true }, title: 'Cost of Goods Upload'},
        component: () => import(/* webpackChunkName: "tools" */ '../views/ToolsPage.vue'),
    },
    {
        path: '/qrscanner',
        name: 'qrscanner',
        component: () => import(/* webpackChunkName: "tools" */ '../views/QrScanner.vue')
    },
    {
        path: '/tracker_curb/:id?',
        name: 'tracker_curb',
        component: () => import(/* webpackChunkName: "businessObject" */ '../views/BusinessObjectPage.vue'),
        props: true,
        meta: {access: { isStaff: true }, title: 'Tracker Curb' }
    },


];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if (helpers.canAccess(to.meta.access, appStore.state)) {
        if (!appStore.state.termsDate && to.path !== '/termsandconditions' && appStore.state.loggedIn && !appStore.isStaff()) {
            next('/termsandconditions');
        } else {
            appStore.setTitle(to.meta.title ? [to.meta.title] : []);
            next();
        }
    } else {
        next();
    }


});

export default router;
