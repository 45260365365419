<template>
    <Modal id="upload" heading="File Upload" @close="close">
        <template v-slot:body>
            <div v-if="state.file !== null && state.fileID">
                <keep-alive>
                    <component v-bind:is="currentComponent" style="overflow-y: scroll!important; max-height: 425px; margin-bottom: 5px"></component>
                </keep-alive>
            </div>

            <!-- TODO maybe a watch method or something to auto-trigger the upload instead of showing this to the user -->
            <div class="form-group" v-show="!state.fileID && state.progress === 0">
                <label class="btn btn-default">
                    Choose file
                    <input type="file" ref="file" name="files[]" @change="uploadFile()" />
                </label>
            </div>
            <div v-show="state.fileID && appState.fileUploadContext !== 'cdi_file'">
                <span v-show="state.allowAttach">
                    <button class="btn btn-primary btn-block" type="button" @click='attachFile()' :disabled="!state.referenceID || isDisabled" >
                        Upload file
                    </button>
                </span>
                <button class="btn btn-default btn-block" type="button" @click='startOver()'>Start over</button>
            </div>
            <br>
            <small>All file uploads are subject to our <a href="/privacy_policy" target="_blank">Privacy Policy</a></small>
        </template>
    </Modal>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";
    import Modal from "@/components/utils/Modal";
    import {store} from "@/store/BusinessObject.store";

    export default {
        name: "FileUpload",
        components: {
            Modal,
            asset: () => import('@/components/utils/fileHandlers/Asset'),
            asset_maintenance_schedule: () => import('@/components/utils/fileHandlers/AssetMaintenanceDocument'),
            batch_utilization_data: () => import('@/components/utils/fileHandlers/BatchUtilizationData'),
            cdi_file: () => import('@/components/utils/fileHandlers/CDIFile'),
            company: () => import('@/components/utils/fileHandlers/Company'),
            conforming_stamp: () => import('@/components/utils/fileHandlers/Conforming'),
            curb: () => import('@/components/utils/fileHandlers/Curb'),
            curb_conforming: () => import('@/components/utils/fileHandlers/CurbConforming'),
            curb_training_documents: () => import('@/components/utils/fileHandlers/CurbTrainingDocument'),
            curb_variant: () => import('@/components/utils/fileHandlers/CurbVariant'),
            eco: () => import('@/components/utils/fileHandlers/ECO'),
            material: () => import('@/components/utils/fileHandlers/Material'),
            item_batch: () => import('@/components/utils/fileHandlers/ItemBatch'),
            model: () => import('@/components/utils/fileHandlers/ModelImport'),
            part: () => import('@/components/utils/fileHandlers/Part'),
            po: () => import('@/components/utils/fileHandlers/PO'),
            quote: () => import('@/components/utils/fileHandlers/Quote'),
            quote_notebook: () => import('@/components/utils/fileHandlers/QuoteNotebook'),
            quote_curb: () => import('@/components/utils/fileHandlers/QuoteCurb'),
            shipping_data: () => import('@/components/utils/fileHandlers/ShippingData'),
            tracker_mer: () => import('@/components/utils/fileHandlers/TrackerMER'),
            user: () => import('@/components/utils/fileHandlers/User'),
            tracker: () => import('@/components/utils/fileHandlers/Tracker'),
            tracker_curb: () => import('@/components/utils/fileHandlers/TrackerCurb'),
            // Bad naming but needs to be this way because of app-reliance
            notebook: () => import('@/components/utils/fileHandlers/TrackerNotebook'),
            cogs: () => import('@/components/utils/fileHandlers/Cogs.vue'),
            maintenance_log: () => import('@/components/utils/fileHandlers/MaintenanceLog'),
            maintenance_photo: () => import('@/components/utils/fileHandlers/MaintenancePhoto')
        },
        created() {
            this.$appStore.setFileUploadCallback(function () {
                store.load(store.state.object.id);
            });
        },
        data() {
            return {
                state: fileUploadStore.state,
                appState: this.$appStore.state,
                isDisabled: false,
            }
        },
        methods: {
            uploadFile: function () {
                fileUploadStore.setFile(this.$refs.file.files[0]);
                fileUploadStore.setTitle(this.$refs.file.files[0].name);
                fileUploadStore.uploadFile();
            },
            attachFile: function () {
                fileUploadStore.attachFile();
                this.close();
                this.$refs.file.value = '';
            },
            startOver: function () {
                fileUploadStore.resetData(true);
                this.$appStore.setFileUploadContext('cdi_file');
            },
            close: function () {
                fileUploadStore.resetData();
                this.$refs.file.value = null;
            }
        },
        computed: {
            currentComponent: function() {
                if (!this.appState.fileUploadContext) {
                    return null;
                }

                let current = `${this.appState.fileUploadContext}`;
                this.$appStore.setFileUploadContext(current);

                return current;
            },
          conformingTypeID: function() {
              return this.state.conformingTypeID;
          },
          fileUploadContext: function() {
              return this.appState.fileUploadContext
          }
        },
      watch: {
          currentComponent: function () {
              if (this.appState.fileUploadContext === "curb_conforming") {
                  this.isDisabled = true;
              }
          },
          conformingTypeID: function () {
              if (this.state.conformingTypeID > 0) {
                  this.isDisabled = false;
              }
          },
          fileUploadContext: function () {
              if (this.appState.fileUploadContext === "maintenance_log") {
                  let cdiLocation = this.appState.fileUploadLocation
                  this.$appStore.setFileUploadCallback(function () {
                      store.apiWithObjectLoad('to_json', {
                          'params[cdi_location_id]': cdiLocation,
                      })
                  });
              }
          }
      }
    }
</script>

<style scoped>
    input[type="file"] {
        display: none;
    }
</style>